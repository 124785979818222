// Page for users to login
import React from 'react';
import { useState, useEffect } from 'react';
import Button from 'react-bootstrap/Button';
import Form from 'react-bootstrap/Form';

const Login = ({ user, setCurrentUser, client }) => {
  
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');

  function submitLogin(e) {
    e.preventDefault();
    client.post(
      "/user_api/login",
      {
        email: email,
        password: password
      }
    ).then(function(res) {
      setCurrentUser(current => true);
    })
    .catch(function(error) {
      console.log("Login");
    });
  };

  if (user) {
    return (
      <div className="center">
        <h2>You are logged in...</h2>
      </div>
    );
  }
  else {
    return (
      <div className="center">
        <Form onSubmit={e => submitLogin(e)}>
          <Form.Group className="mb-3" controlId="formBasicEmail">
            <Form.Label>Email address</Form.Label>
            <Form.Control type="email" placeholder="Enter email" value={email} onChange={e => setEmail(e.target.value)} />
            <Form.Text className="text-muted">
              We'll never share your email with anyone else.
            </Form.Text>
          </Form.Group>
          <Form.Group className="mb-3" controlId="formBasicPassword">
            <Form.Label>Password</Form.Label>
            <Form.Control type="password" placeholder="Password" value={password} onChange={e => setPassword(e.target.value)} />
          </Form.Group>
          <Button variant="primary" type="submit">
            Submit
          </Button>
        </Form>
      </div>
    );
  }
}

export default Login;