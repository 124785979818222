
export function objectToCSV (data) {

    const csvRows = [];

    const headers = ["id","name","punch_in","punch_out","duration"]

    csvRows.push(headers.join(','));

    for (const row of data) {
        const values = headers.map(header => {
            if (header == "name") {
                const val = row["employee_id"]["first_name"]+" "+row["employee_id"]["last_name"];
                return `"${val}"`;
            } else {
                const val = row[header];
                return `"${val}"`;
            }
        });

        csvRows.push(values.join(','));
    }
    
    return csvRows.join('\n');
}



