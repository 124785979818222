// View/edit/manually edit work periods, warnings, and special periods.
// Also download reports from this page through filtering and export options
import React from 'react';
import 'bootstrap/dist/css/bootstrap.min.css';
import AddWorkPeriod from './AddWorkPeriod';

const WorkPeriods = ({client, user, userInfo}) => {

  if (user && userInfo.user_level == 1) {
    return (
      <>
        <div className='=App'>
          <AddWorkPeriod client={client} user={user} />
        </div>
      </>
    );
  } else {
    return (
      <div>
          Access denied.
      </div>
    );
  };
};

export default WorkPeriods;