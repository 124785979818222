import { useState, useEffect } from "react";
import { ListGroup, Card, Button, Form, Col, Row } from "react-bootstrap";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faSquarePen, faTrash } from '@fortawesome/free-solid-svg-icons';
import Select from 'react-select';
import { objectToCSV } from './csvConverter';

const AddWorkPeriod = ({ client, user }) => {

  const [punch_in, setPunchIn] = useState("");
  const [employee_id, setEmployeeId] = useState([]);
  const [in_progress, setInProgress] = useState(false);
  const [punch_out, setPunchOut] = useState("");
  const [workers, setWorkers] = useState([])
  const [workPeriods, setWorkPeriods] = useState([]);
  const [selectedWPID, setSelectedWPID] = useState();
  const [selectedOption, setSelectedOption] = useState({});
  const [filter, setFilter] = useState(false);

  const [tempInDate, setTempInDate] = useState("0000-00-00");
  const [tempInTime, setTempInTime] = useState("00:00");
  const [tempOutDate, setTempOutDate] = useState("0000-00-00");
  const [tempOutTime, setTempOutTime] = useState("00:00");
  const [startFilter, setStartFilter] = useState("0000-00-00 00:00:00");
  const [endFilter, setEndFilter] = useState("0000-00-00 00:00:00");

  useEffect(() => {
    refreshWorkers();
    refreshWorkPeriods();
  }, [user]);

  const refreshWorkers = () => {
    client.get("/api/employees")
      .then((res) => {
        setWorkers(res.data);
      })
      .catch(function(error) {
        console.log("Employees access denied");
      });
  };

  const refreshWorkPeriods = () => {
    client.get("/api/workperiods/on_job")
      .then((res) => {
        setWorkPeriods(res.data);
      })
      .catch(function(error) {
        console.log("Workperiods/on_job access denied");
      });
  };

  const onSubmit = (e) => {
    e.preventDefault();
    let item = { employee_id, punch_in, in_progress, punch_out };
    client.post("/api/workperiods/", item).then(() => {refreshWorkPeriods(); clearSelectedPeriod();})
    .catch(function(error) {
        console.log("Workperiods/post access denied");
      });
  };

  const onUpdate = (id) => {
    let item = { employee_id, punch_in, in_progress, punch_out };
    client.patch(`/api/workperiods/${id}/`, item).then((res) => {refreshWorkPeriods(); clearSelectedPeriod();})
    .catch(function(error) {
        console.log("Workperiods/patch access denied");
      });
  };

  const onDelete = (id) => {
    client.delete(`/api/workperiods/${id}/`).then((res) => refreshWorkPeriods())
    .catch(function(error) {
        console.log("Workperiods/delete access denied");
      });
  };

  const apply_filter = () => {
    client.get("/api/workperiods/by_date_range/", {
        params: {
            startFilter: startFilter,
            endFilter: endFilter,
          }
    })
      .then((res) => {
        setWorkPeriods(res.data);
      })
      .catch(function(error) {
        console.log("Workperiods/on_job access denied");
      });
  };

  const downloadFile = ({ data, fileName, fileType }) => {
    const blob = new Blob([data], { type: fileType });
  
    const a = document.createElement('a');
    a.download = fileName;
    a.href = window.URL.createObjectURL(blob);
    const clickEvt = new MouseEvent('click', {
      view: window,
      bubbles: true,
      cancelable: true,
    });
    a.dispatchEvent(clickEvt);
    a.remove();
  };

  const exportToCSV = (e) => {
    e.preventDefault();
    const csv = objectToCSV(workPeriods);
    const fileName = startFilter.slice(0,10) + "___" + endFilter.slice(0,10) + ".csv";
    downloadFile({ data: csv, fileName: fileName, fileType: 'text/csv' });
  };

  const selectWorkPeriod = (id) => {
    let item = workPeriods.filter((workPeriod) => workPeriod.id === id)[0];
    setSelectedWPID(id);
    setPunchIn(item.punch_in);
    setTempInDate(item.punch_in.slice(0,10));
    setTempInTime(item.punch_in.slice(11,16));
    setPunchOut(item.punch_out);
    setTempOutDate(item.punch_out.slice(0,10));
    setTempOutTime(item.punch_out.slice(11,16));
    setInProgress(item.in_progress);
    setEmployeeId(item.employee_id.id);
    setSelectedOption({key: item.employee_id.id, label: item.employee_id.first_name+' '+item.employee_id.last_name, value: item.employee_id.id});
  };

  const clearSelectedPeriod = () => {
    setSelectedWPID("");
    setPunchIn("");
    setTempInDate("0000-00-00");
    setTempInTime("00:00");
    setPunchOut("");
    setTempOutDate("0000-00-00");
    setTempOutTime("00:00");
    setInProgress(false);
    setEmployeeId([]);
    setSelectedOption({});
  }

  if (user) {
    return (
        <div className="container mt-3">
        <div className="row">
            <div className="col-md-3">
            <h3 className="float-left">Make/edit work period</h3>
            <Form onSubmit={onSubmit} className="mt-2" id="myForm">
                <Row>
                    <Form.Group as={Col} className="mb-1" controlId="formBarcodeId">
                        <Form.Label>WorkPeriod #</Form.Label>
                        <Form.Control
                            type="text"
                            value={selectedWPID || ""}
                            disabled={true}
                        />
                    </Form.Group>

                    <Form.Group as={Col} className="mb-1" controlId="formInProgress">
                    <Form.Label>In progress?</Form.Label>
                    <Form.Check
                        data-bs-theme="dark"
                        type="checkbox"
                        id="default-checkbox"
                        checked={in_progress || false} // added '|| false' because of warning about changing controlled/uncontrolled component
                        onChange={(e) => setInProgress(e.target.checked)}
                    />
                    </Form.Group>
                </Row>

                <Form.Group className="mb-1" controlId="formBasicFirstName">
                <Form.Label>Employee</Form.Label>
                <Select 
                    label={selectedOption.label}
                    value={selectedOption}
                    onChange={e => {setSelectedOption(e);setEmployeeId(e.value);}}
                    options={workers.map(o => ({ key:o.id, label:o.first_name+' '+o.last_name, value:o.id }))}>
                </Select>
                </Form.Group>

                <Form.Group className="mb-1" controlId="formInProgress">
                    <Form.Label>Start Date</Form.Label>
                    <Form.Control 
                        type="date" 
                        value={tempInDate || ""}
                        onChange={(e) => {setTempInDate(e.target.value); setPunchIn(e.target.value+' '+tempInTime+':00');}}/>
                </Form.Group>

                <Form.Group className="mb-1" controlId="formInProgress">
                    <Form.Label>Start Time</Form.Label>
                    <Form.Control 
                        type="time" 
                        value={tempInTime || ""}
                        onChange={(e) => {setTempInTime(e.target.value); setPunchIn(tempInDate+' '+e.target.value+':00');}}/>
                </Form.Group>

                <Form.Group className="mb-1" controlId="formInProgress">
                    <Form.Label>End Date</Form.Label>
                    <Form.Control 
                        type="date" 
                        value={tempOutDate || ""}
                        onChange={(e) => {setTempOutDate(e.target.value); setPunchOut(e.target.value+' '+tempOutTime+':00');}}/>
                </Form.Group>

                <Form.Group className="mb-1" controlId="formInProgress">
                    <Form.Label>End Time</Form.Label>
                    <Form.Control 
                        type="time" 
                        value={tempOutTime || ""}
                        onChange={(e) => {setTempOutTime(e.target.value); setPunchOut(tempOutDate+' '+e.target.value+':00');}}/>
                </Form.Group>
            
                <div className="float-right">
                <Button
                    size="md"
                    variant="success"
                    type="submit"
                    onClick={onSubmit}
                    className="mx-2"
                >
                    Save
                </Button>
                <Button
                    size="md"
                    variant="primary"
                    type="button"
                    onClick={() => onUpdate(selectedWPID)}
                    className="mx-2"
                >
                    Update
                </Button>

                <Button
                    size="md"
                    variant="secondary"
                    type="button"
                    onClick={() => clearSelectedPeriod()}
                    className="mx-2"
                >
                    Clear
                </Button>
                </div>
                <br></br>
                <br></br>
            </Form>
            </div>


            <div className="col-md-9 m">
            <div className="row">
            <Form onSubmit={onSubmit} className="mt-0" id="myForm2">
                <Row>
                    
                    <Form.Group as={Col} className="mb-0" controlId="formFilter">
                    <h4 className="center">Filter --&gt;</h4>
                        {/* <Form.Label>Filter --&gt;</Form.Label>
                        <Form.Check
                            data-bs-theme="dark"
                            type="checkbox"
                            id="default-checkbox"
                            checked={filter || false}
                            onChange={(e) => setFilter(e.target.checked)}
                        /> */}
                    </Form.Group>

                    <Form.Group as={Col} className="mb-2" controlId="formInProgress">
                        <Form.Label>Start Date (Clock in)</Form.Label>
                        <Form.Control 
                            type="date" 
                            value={startFilter.slice(0,10) || ""}
                            onChange={(e) => setStartFilter(e.target.value+' '+'00:00:00')}/>
                    </Form.Group>

                    <Form.Group as={Col} className="mb-2" controlId="formInProgress">
                        <Form.Label>End Date (Clock in)</Form.Label>
                        <Form.Control 
                            type="date" 
                            value={endFilter.slice(0,10) || ""}
                            onChange={(e) => setEndFilter(e.target.value+' '+'00:00:00')}/>
                    </Form.Group>

                    <Col>
                    <Button
                    className="mb-1"
                    size="md"
                    variant="primary"
                    type="button"
                    onClick={() => apply_filter()}
                    >
                    Apply Filter
                    </Button>
                    
                    <Button
                    className="mb-1"
                    size="md"
                    variant="secondary"
                    type="button"
                    onClick={() => {refreshWorkPeriods(); setStartFilter("0000-00-00 00:00:00"); setEndFilter("0000-00-00 00:00:00");}}
                    >
                    Remove Filter
                    </Button>
                    </Col>

                    <Col>
                    <Button
                    className="mt-3"
                    size="md"
                    variant="warning"
                    type="button"
                    onClick={(e) => {exportToCSV(e);}}
                    >
                    Export
                    </Button>
                    </Col>

                </Row>
            </Form>
            </div>

            <div className="tableContainer">
            <table className="table">
                <thead background_color="gray">
                <tr>
                    <th scope="col">#</th>
                    <th scope="col">Employee</th>
                    <th scope="col">In</th>
                    <th scope="col">Out</th>
                    <th scope="col">In progress?</th>
                    <th scope="col">Department</th>
                    <th scope="col"></th>
                </tr>
                </thead>
                <tbody>
                {workPeriods.map((workPeriod, index) => {
                    return (
                    <tr key={index}>
                        <th scope="row">{workPeriod.id}</th>
                        <td>{workPeriod.employee_id.first_name} {workPeriod.employee_id.last_name}</td>
                        <td>{workPeriod.punch_in.slice(0,20)}</td>
                        <td>{workPeriod.punch_out.slice(0,20)}</td>
                        <td>
                            <input type='checkbox' name='onClock' disabled={true} checked={workPeriod.in_progress}></input>
                        </td>
                        <td>{workPeriod.employee_id.department}</td>
                        <td>
                            <Button>
                                <FontAwesomeIcon icon={faSquarePen} 
                                onClick={() => selectWorkPeriod(workPeriod.id)}/>
                            </Button>&nbsp;
                            <Button variant="danger">
                                <FontAwesomeIcon icon={faTrash} 
                                onClick={() => onDelete(workPeriod.id)}/>
                            </Button>
                        </td>
                    </tr>
                    );
                })}
                </tbody>
            </table>
            </div>
            </div>
        </div>
        </div>
    );
  } else {
    return (
      <div>
          Access denied.
      </div>
    );
  };
};

export default AddWorkPeriod;