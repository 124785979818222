// View who is clocked in/out, active/inactive, and create/edit employees
import 'bootstrap/dist/css/bootstrap.min.css';
import AddWorker from './AddWorker';

const Workforce = ({ client, user, userInfo }) => {

  if (user && userInfo.user_level == 1) {
    return (
      <>
        <div className='=App'>
          <AddWorker client={client} user={user} />
        </div>
      </>
    );
  } else {
    return (
      <div>
          Access denied.
      </div>
    );
  };
};

export default Workforce;