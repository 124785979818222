import { useState, useEffect } from "react";
import { ListGroup, Card, Button, Form, Col, Row } from "react-bootstrap";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faSquarePen, faTrash } from '@fortawesome/free-solid-svg-icons'

const AddWorker = ({ client, user }) => {


  const [active, setActive] = useState(false);
  const [clocked_in, setClocked_in] = useState(false);
  const [first_name, setFirst_name] = useState("");
  const [last_name, setLast_name] = useState("");
  const [rfid, setRfid] = useState();
  const [barcode_id, setBarcode_id] = useState();
  const [department, setDepartment] = useState(""); 
  const [workerId, setWorkerId] = useState(null);
  const [workers, setWorkers] = useState([])
  const [searchFname, setSearchFname] = useState("");
  const [searchLname, setSearchLname] = useState("");

  useEffect(() => {
    refreshWorkers();
  }, [user]);

  const refreshWorkers = () => {
    client.get("/api/employees")
      .then((res) => {
        setWorkers(res.data);
      })
      .catch(function(error) {
        console.log("Employees access denied");
      });
  };

  const onSubmit = (e) => {
    e.preventDefault();
    let item = { active, clocked_in, first_name, last_name, rfid, barcode_id, department };
    client.post("/api/employees/", item).then(() => {refreshWorkers(); clearSelectedWorker();});
  };

  const onUpdate = (id) => {
    let item = { active, clocked_in, first_name, last_name, rfid, barcode_id, department };
    client.patch(`/api/employees/${id}/`, item).then((res) => {refreshWorkers(); clearSelectedWorker();});
  };

  const onDelete = (id) => {
    client.delete(`/api/employees/${id}/`).then((res) => {refreshWorkers(); clearSelectedWorker();});
  };

  const apply_filter = () => {
    client.get("/api/employees/search_name/", {
        params: {
            searchFname: searchFname,
            searchLname: searchLname,
          }
    })
      .then((res) => {
        setWorkers(res.data);
      })
      .catch(function(error) {
        console.log("employees/search_name access denied");
      });
  }

  const selectWorker = (id) => {
    let item = workers.filter((worker) => worker.id === id)[0];
    setActive(item.active);
    setBarcode_id(item.barcode_id);
    setClocked_in(item.clocked_in);
    setDepartment(item.department);
    setFirst_name(item.first_name);
    setLast_name(item.last_name);
    setRfid(item.rfid);
    setWorkerId(item.id);
  };

  const clearSelectedWorker = () => {
    setActive(false);
    setBarcode_id("");
    setClocked_in("");
    setDepartment("");
    setFirst_name("");
    setLast_name("");
    setRfid("");
    setWorkerId("");
  };

  if (user) {
    return (
        <div className="container mt-3">
        <div className="row">
            <div className="col-md-3">
            <h3 className="float-left">Register/edit employee</h3>
            <Form onSubmit={onSubmit} className="mt-2" id="myForm">
              <Row>
                <Form.Group as={Col} className="mb-1" controlId="formBarcodeId">
                    <Form.Label>Employee #</Form.Label>
                    <Form.Control
                        type="text"
                        value={workerId || ""}
                        disabled={true}
                    />
                </Form.Group>

                <Form.Group as={Col} className="mb-1" controlId="formActive">
                <Form.Label>Active</Form.Label>
                <Form.Check
                    data-bs-theme="dark"
                    type="checkbox"
                    id="default-checkbox"
                    checked={active || false}
                    onChange={(e) => setActive(e.target.checked)}
                />
                </Form.Group>
              </Row>

                <Form.Group className="mb-1" controlId="formBasicFirstName">
                <Form.Label>First Name</Form.Label>
                <Form.Control
                    type="text"
                    placeholder="Enter First Name"
                    value={first_name || ''}
                    onChange={(e) => setFirst_name(e.target.value)}
                />
                </Form.Group>

                <Form.Group className="mb-1" controlId="formBasicLastName">
                <Form.Label>Last Name</Form.Label>
                <Form.Control
                    type="text"
                    placeholder="Enter Last Name"
                    value={last_name || ''}
                    onChange={(e) => setLast_name(e.target.value)}
                />
                </Form.Group>

                <Form.Group className="mb-1" controlId="formBarcodeId">
                <Form.Label>Barcode ID</Form.Label>
                <Form.Control
                    type="text"
                    placeholder="Enter Barcode ID"
                    value={barcode_id || ''}
                    onChange={(e) => {setBarcode_id(e.target.value)}}
                />
                </Form.Group>

                <Form.Group className="mb-1" controlId="formRfid">
                <Form.Label>RFID</Form.Label>
                <Form.Control
                    type="text"
                    placeholder="Enter RFID"
                    value={rfid || ''}
                    onChange={(e) => {setRfid(e.target.value)}}
                />
                </Form.Group>

                <Form.Group className="mb-1" controlId="formDepartment">
                <Form.Label>Department</Form.Label>
                <Form.Control
                    type="text"
                    placeholder="Enter Department"
                    value={department || ''}
                    onChange={(e) => setDepartment(e.target.value)}
                />
                </Form.Group>

                <div className="float-right">
                <Button
                    size="md"
                    variant="success"
                    type="submit"
                    onClick={onSubmit}
                    className="mx-2"
                >
                    Save
                </Button>
                <Button
                    size="md"
                    variant="primary"
                    type="button"
                    onClick={() => onUpdate(workerId)}
                    className="mx-2"
                >
                    Update
                </Button>

                <Button
                    size="md"
                    variant="secondary"
                    type="button"
                    onClick={() => clearSelectedWorker()}
                    className="mx-2"
                >
                    Clear
                </Button>

                </div>
                <br></br>
                <br></br>
            </Form>
            </div>
            <div className="col-md-9 m">
            <div className="row">
            <Form onSubmit={onSubmit} className="mt-0" id="myForm2">
                <Row>
                    
                    <Form.Group as={Col} className="mb-0" controlId="formFilter">
                      <h4 className="center">Filter --&gt;</h4>
                    </Form.Group>

                    <Form.Group as={Col} className="mb-2" controlId="formInProgress">
                        <Form.Label>First Name</Form.Label>
                        <Form.Control 
                            type="text" 
                            value={searchFname || ""}
                            onChange={(e) => setSearchFname(e.target.value)}/>
                    </Form.Group>

                    <Form.Group as={Col} className="mb-2" controlId="formInProgress">
                        <Form.Label>Last Name</Form.Label>
                        <Form.Control 
                            type="text" 
                            value={searchLname || ""}
                            onChange={(e) => setSearchLname(e.target.value)}/>
                    </Form.Group>

                    <Col>
                    <Button
                    className="mb-1"
                    size="md"
                    variant="primary"
                    type="button"
                    onClick={() => apply_filter()}
                    >
                    Apply Filter
                    </Button>

                    <Button
                    className="mb-1"
                    size="md"
                    variant="secondary"
                    type="button"
                    onClick={() => {refreshWorkers(); setSearchFname(""); setSearchLname("");}}
                    >
                    Remove Filter
                    </Button>
                    </Col>
                </Row>
            </Form>
            </div>

            <table className="table">
                <thead>
                <tr>
                    <th scope="col">#</th>
                    <th scope="col">Employee</th>
                    <th scope="col">Active</th>
                    <th scope="col">Department</th>
                    <th scope="col"></th>
                </tr>
                </thead>
                <tbody>
                {workers.map((worker, index) => {
                    return (
                    <tr key={index}>
                        <th scope="row">{worker.id}</th>
                        <td>{worker.first_name} {worker.last_name}</td>
                        <td>
                            <input type='checkbox' name='active' disabled={true} checked={worker.active}></input>
                        </td>
                        <td>{worker.department}</td>
                        <td>
                            <Button>
                                <FontAwesomeIcon icon={faSquarePen} 
                                onClick={() => selectWorker(worker.id)}/>
                            </Button>&nbsp;
                            <Button variant="danger">
                                <FontAwesomeIcon icon={faTrash}  
                                onClick={() => onDelete(worker.id)}/>
                            </Button>
                        </td>
                    </tr>
                    );
                })}
                </tbody>
            </table>
            </div>
        </div>
        </div>
    );
  }
  else {
    <div>
        Access denied.
    </div>
  };
};

export default AddWorker;