// Simple page for clocking in/out with auto-cursor for scanning
import React from 'react';
import { useState, useEffect } from 'react';
import Clock from 'react-live-clock';
import moment from 'moment';


const TimeClock = ({ client, user, userInfo }) => {

  const [workerId, setWorkerId] = useState("");

  var perIndex = -1;
  var empId = -1;
  var name = "";

  useEffect(() => {

  }, [user]);

  const verifyClockout = (event) => {
    if (window.confirm("Clock OUT " + name + "?")) {
      clockOut();
    };
    setWorkerId("");
    event.target.reset();
  };

  const verifyClockin = (event) => {
    if (window.confirm("Clock IN " + name + "?")) {
      clockIn();
    };
    setWorkerId("");
    event.target.reset();
  };

  const clockOut = () => {
    let in_progress = false;
    let punch_out = moment().format('YYYY-MM-DD HH:mm:ss');
    let item = { in_progress, punch_out };
    client.patch(`/api/workperiods/${perIndex}/`, item).then(
      (res) => console.log("Clock out successful.")).catch(function(error) {
      console.log("Clock out denied");
    });
  };

  const clockIn = () => {
    let employee_id = empId;
    let punch_in = moment().format('YYYY-MM-DD HH:mm:ss');
    let punch_out = moment().format('YYYY-MM-DD HH:mm:ss');
    let item = { employee_id, punch_in, punch_out };
    client.post("/api/workperiods/", item).then(
      (res) => console.log("Clock in successful.")).catch(function(error) {
      console.log("Clock in denied");
    });
  };

  const searchEmployeeId = (callback) => {
    client.get("/api/employees/search_num/", {
      params: {
          searchNum: workerId
        }
    })
    .then((res) => {
      if (res.data.length == 0) {
        alert("Invalid number: try again or talk to Evan.");
      } else {
        empId = res.data[0].id;
        name = res.data[0].first_name + " " + res.data[0].last_name;
        callback();
      }
    })
    .catch(function(error) {
      console.log("employees/search_num access denied");
    });
  };

  const handleSubmit = (event) => {
    event.preventDefault();
    client.get("/api/workperiods/search_num/", {
      params: {
          searchNum: workerId
        }
    })
    .then((res) => {
      if (res.data.length == 0) {
        searchEmployeeId(() => verifyClockin(event));
      } else {
        perIndex = res.data[0].id;
        name = res.data[0].employee_id.first_name + " " + res.data[0].employee_id.last_name;
        verifyClockout(event);
      };
    })
    .catch(function(error) {
      console.log("Workperiods/search_num access denied");
      setWorkerId("");
      event.target.reset();
    });
  };

  if (user && userInfo.user_level > 0) {
    return (
      <>
        <div className='center'>
          <h2>
            <Clock className='center' format="h:mm:ss A" interval={1000} ticking={true} timezone='America/Chicago' />
            <br></br>
            <Clock className='center' format="dddd, MMMM D, YYYY" timezone='America/Chicago' />
          </h2>
        </div>
        <div className='center'>
          <form onSubmit={handleSubmit} id="myform">
            <label>Worker ID (barcode or rfid):&nbsp;
              <input autoFocus type="text"
                value={workerId}
                onChange={(e) => setWorkerId(e.target.value)}/>
            </label>
            <input type="submit" />
          </form>
        </div>
      </>
    )
  }
  return (
    <>
      <div className='center'>
        <h2>
          <Clock className='center' format="h:mm:ss A" interval={1000} ticking={true} timezone='America/Chicago' />
          <br></br>
          <Clock className='center' format="dddd, MMMM D, YYYY" timezone='America/Chicago' />
        </h2>
      </div>
    </>
  );
};
  
export default TimeClock;

